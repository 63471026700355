.input-field::placeholder {
    color: #525A65;
  }
  
@keyframes popIn {
    0% {
      transform: scale(1);
      opacity: 0.6;
    }
    50% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .input-field.error::placeholder {
    color: #EC0B43; /* Hata varsa kırmızı */
    animation: popIn 0.3s ease-in-out;
  }
  .responsive-flex {
  
    flex-direction: row; /* Varsayılan olarak dikey */

  }

  @media (max-width: 768px) {
    .responsive-flex {
      flex-direction: column; /* Tablet ve üzeri yatay yap */
    }
  }
